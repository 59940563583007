import React, { useEffect, useRef, useState } from 'react';
import '../Styles/ContactUs.css';
import { Container, Row, Col } from 'react-bootstrap';
import { FaRegAddressCard } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import Aos from 'aos';
import emailjs from "@emailjs/browser";
import 'aos/dist/aos.css';

import Form from "react-bootstrap/Form";

import Button from "react-bootstrap/Button";

const ContactUs = () => {
  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  }, []);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_o4u7nef",
        "template_k1f9e5i",
        form.current,
        "J5LtbyU0W_cvlO2H9"
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          alert("send Successfully")
          window.location.reload("")

        },
        (error) => {
          console.error("Email sending failed:", error.text);
        }
      );
  };
  return (
    //     <div>
    //       <div className="contact-img">
    //         <img src="/Assets/con-head.avif" alt="Contact Us" className="img-fluid" />
    //         <div className="contact-heading">
    //           <h1>Contact Us</h1>
    //         </div>
    //       </div>

    //       <Container fluid className='mb-4'>
    //   <div id='background'>
    //     <Row className='justify-content-center'>
    //       <Col md={4} sm={12} className='mb-3' data-aos="zoom-in" data-aos-delay="100">
    //         <div className='border text-center h-100 d-flex flex-column justify-content-between'>
    //           <p className='fs-5 mb-0'><FaRegAddressCard /></p>
    //           <p className='fs-5'>Opposite to Aruna Junior college, Cherambane, on the way to Bagamandala</p>
    //         </div>
    //       </Col>

    //       <Col md={4} sm={12} className='mb-3' data-aos="zoom-in" data-aos-delay="200">
    //         <div className='border text-center h-100 d-flex flex-column justify-content-between'>
    //           <p className='fs-5 mb-0'><FaPhoneVolume /></p>
    //           <p className='fs-5'> 08272-235118 <br />+91 9886494048 <br />+91 9449685324</p>
    //         </div>
    //       </Col>

    //       <Col md={4} sm={12} className='mb-3' data-aos="zoom-in" data-aos-delay="300">
    //         <div className='border text-center h-100 d-flex flex-column justify-content-between'>
    //           <p className='fs-5 mb-0'><MdEmail /></p>
    //           <p className='fs-5'>youremail123@gmail.com</p>
    //         </div>
    //       </Col>
    //     </Row>
    //   </div>
    // </Container>

    //     </div>
    <div  className='contactus-overall'>

      <div className="contact-img" data-aos="zoom-in" data-aos-duration="2000">
        <img src="/Assets/estate9.jpg" alt="Contact Us" className="img-fluid" />
        <div className="contact-heading">
          <h1>Contact Us</h1>
        </div>
      </div>

      <div className=' p-2 get-in-touch-overalldiv'  >

        <Container className='cont'>
          <div className='p-3'>
            <h1 style={{ fontWeight: "bold" }}>GET IN TOUCH</h1>
          </div>
          <div className='get-in-touch-main'>
            <div className=' p-2 get-in-touch-sub' style={{ width: "36%" }} >
              <div className=' p-2 form-icon-div'><FaRegAddressCard className='form-address-icon ' /></div>
              <div className='p-2 get-in-touch-text'>
                <h4>Address</h4>
                <p className='text-start ps-5 get-in-touch-para' id='address-contact'>Gurukurpa Homestay Opp to Mithra Complex,Cherambane,
                   On the Way to Bagamandala,  Coorg-571201.</p>
              </div>
            </div>
            <div className='  p-2 mb-5 get-in-touch-sub' style={{ width: "25%" }}>
              <div className='  p-2 form-icon-div'><MdEmail className='form-address-icon' /></div>
              <div className='p-2 get-in-touch-text'>
                <h4>Email Us</h4>
                <p>saigurufx@gmail.com</p>
              </div>
            </div>
            <div className='  p-2 get-in-touch-sub' style={{ width: "25%" }}>
              <div className='  p-2 mb-1 form-icon-div'><FaPhoneVolume className='form-address-icon' /></div>
              <div className='contactus-numbers p-2'>
                <h4>Call us</h4>
                {/* <p>08272-235118</p> */}
                <p>9448515921</p>
                <p>9886494048</p>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Container>
        <div className="row ">
          <div className='col-md-5 p-5   form-detail-ryt ' data-aos="fade-up" data-aos-duration="2000">
            {/* <div className='border p-3 mb-2 mt-5' style={{ borderRadius: "6px" }} data-aos="fade-up" data-aos-delay="200">
              <div className='form-address'>
                <div><FaRegAddressCard className='form-address-icon' /></div>
                <div>
                  <h4>Address</h4>
                  <p>Gurukurpa Homestay Opp to Aruna Junior college,
                    Cherambane, on the way to Bagamandala</p>
                </div>
              </div>
            </div>
            <div className='border p-3 mb-2' style={{ borderRadius: "6px" }} data-aos="zoom-in" data-aos-delay="200">
              <div className='form-address'>
                <div><MdEmail className='form-address-icon' /></div>
                <div>
                  <h4>Email Us</h4>
                  <p>saigurufx@gmail.com</p>
                </div>
              </div>
            </div>
            <div className='border p-3 mb-2' style={{ borderRadius: "6px" }} data-aos="fade-up" data-aos-delay="200" >
              <div className='form-address'>
                <div><FaPhoneVolume className='form-address-icon' /></div>
                <div>
                  <h4>Call us</h4>
                  <p>9449685324</p>
                </div>
              </div>
            </div> */}

            <div>
              <h1 style={{ fontFamily: "cursive" }}>Message Us</h1>
              <p className='mt-4'>Welcome to Gurukrupa Homestay, a hidden gem nestled in the heart of
                Coorg's lush landscapes. Situated amidst sprawling coffee estates and
                enveloped in serene greenery, our estate stay promises an unparalleled
                retreat into nature's embrace.</p>
              <p>Imagine waking up to the crisp mountain air, surrounded by verdant
                coffee plantations and picturesque paddy fields. At Gurukrupa Homestay,
                you can unwind by following a gentle stream that meanders through the hilly
                valleys, offering a peaceful sanctuary for contemplation and relaxation.
                Embark on invigorating estate walks and explore the rich biodiversity
                that thrives in this pristine environment.</p>
            </div>

          </div>
          <div className='col-md-7 p-5 form-detail-ryt' data-aos="fade-down" data-aos-duration="2000">
           
            {/* <div className='form p-4'>
              <label >
                <h4>Full Name</h4>
              </label>
              <input type="text" placeholder='Enter full name' name="user_fname" className='inputField' />

              <label >
                <h4>Email Address</h4>
              </label>
              <input type="text" placeholder='Enter Email Address' name="user_email" className='inputField' />

              <label >
                <h4>Phone Number</h4>
              </label>
              <input type="text" placeholder='Enter Phone Number' name="user_phone" className='inputField' />

              <label >
                <h4>Message</h4>
              </label>
              <textarea placeholder='Enter your message' name="user_message" className='inputField'></textarea>

              
            </div> */}
            <Form ref={form} onSubmit={sendEmail}>
              <Row>
                <Col>
                <h4>Full Name</h4>
                  <Form.Control
                    placeholder="Full name"
                    type="text"
                    id="user_Fname"
                    name="user_fname"
                    required
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                <h4>Email Address</h4>
                  <Form.Control
                    placeholder="Email"
                    type="email"
                    id="user_email"
                    name="user_email"
                    required
                  />
                </Col>

              </Row>
              <br />
              <Row>
                <Col>
                <h4>Phone Number</h4>
                  <Form.Control
                    placeholder="Phone Number"
                    name="user_phone"
                    required
                  />
                </Col>
              </Row>
              <br />
              <h4>Message</h4>
              <Form.Control
                as="textarea"
                type="text"
                name="user_message"
                placeholder="Message"
                style={{ height: "100px" }}
              />

              <br />
              <div className="d-flex align-items-center justify-content-center">
                <div className="p-1">
                  <Button
                    type="submit"
                    className="btn-danger p-2"
                    style={{
                      backgroundColor: "#AF6327",
                      border: "1px solid #AF6327",
                    }}
                  >
                    submit
                  </Button>
                </div>
              </div>
            </Form>

          </div>

        </div>
      </Container>
    </div>


  );
};

export default ContactUs;
