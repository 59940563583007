let GalleryImgData =[
  
    {
        type : "estate",
        image : "/Assets/estate1.jpg"
    },
    {
        type : "estate",
        image : "/Assets/estate2.jpg"
    },
    {
        type : "estate",
        image : "/Assets/estate3.jpg"
    },
    {
        type : "estate",
        image : "/Assets/estate4.jpg"
    },
    {
        type : "estate",
        image : "/Assets/estate5.jpg"
    },
    {
        type : "estate",
        image : "/Assets/estate6.jpg"
    },

    {
        type : "estate",
        image : "/Assets/estate8.jpg"
    },
    {
        type : "estate",
        image : "/Assets/estate9.jpg"
    },

    

    {
        type : "room",
        image : "/Assets/room1.jpg"
    },
    {
        type : "room",
        image : "/Assets/room2.jpg"
    },
    {
        type : "room",
        image : "/Assets/room3.jpg"
    },
    {
        type : "room",
        image : "/Assets/room4.jpg"
    },
    {
        type : "room",
        image : "/Assets/room5.jpg"
    },
    {
        type : "room",
        image : "/Assets/room6.jpg"
    },
    {
        type : "room",
        image : "/Assets/room7.jpg"
    },
    {
        type : "room",
        image : "/Assets/room8.jpg"
    },
    {
        type : "room",
        image : "/Assets/room9.jpg"
        
    },
    {
        type : "room",
        image : "/Assets/room10.jpg"
        
    },
    {
        type : "room",
        image : "/Assets/room11.jpg"
        
    },


    {
        type : "kitchen",
        image : "/Assets/kitchen1.jpg"
    },
    {
        type : "kitchen",
        image : "/Assets/kitchen2.jpg"
    },


    {
        type : "tent",
        image : "/Assets/tent1.jpg"
    },
    {
        type : "tent",
        image : "/Assets/tent2.jpg"
    },

]

export default GalleryImgData;