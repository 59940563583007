import React, { useEffect } from "react";
import "../Styles/Home.css";
// import Carousel from "react-bootstrap/Carousel";
import { useNavigate } from "react-router-dom";
import { MdOutlineSecurity } from "react-icons/md";
import { FaWifi } from "react-icons/fa";
import { TbToolsKitchen3 } from "react-icons/tb";
import { FaHandHoldingWater } from "react-icons/fa";
import Aos from "aos";
import { Container } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const Home = () => {
  const navigate = useNavigate();

  const handleAboutusClick = () => {
    navigate("/aboutus", { replace: true });
  };

  const thingsToDo = () => {
    navigate("/gallery")
  }

  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  }, []);


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };


  return (
    <div className="HomeOverFlow">
      <div className="video-home-page" >
        <div className="video-home-page-text">
          {/* <h1>BEST VACATION EXPERIENCE EVER</h1> */}
          <div style={{color:"white"}}>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={2000}
            autoPlay={true}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={2000}
            containerClass="carousel-container"
            removeArrowOnDeviceType={ ["desktop","mobile"]}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            <div>
            <h1>"Live in the middle of the clouds during the monsoon in Our Homestay"</h1>
            </div>
            <div>
              <h1>"Discover the essence of Coorgian hospitality at our homestay, where every guest is family."</h1>
            </div>
            <div>
              <h1>"Wake up to the aroma of freshly brewed coffee in our peaceful homestay in the heart of Coorg."</h1>
            </div>
            <div>
              <h1>"Escape to nature's embrace at our homestay in scenic Coorg, where relaxation meets adventure."</h1>
            </div>
            
          </Carousel>
        </div>
        </div>

        <div className="video-home-page-video">
          <video controls muted loop autoPlay style={{ objectFit: "cover" }}>
            <source src="/Assets/home-video2.mp4" />
          </video>
        </div>
        
      </div>

      {/* <Carousel
        data-aos="zoom-in"
        data-aos-duration="2000"
        
      >
        <Carousel.Item>
          <Carousel.Caption>
            <h1 className="h-heading">BEST VACATION EXPERIENCE EVER</h1>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src="/Assets/h-img2.jpg" alt="" className="h-img img-fluid" />
          <Carousel.Caption>
            <h1 className="h-heading">COME AS A GUEST LEAVE AS A FRIEND</h1>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src="/Assets/h-img3.jpg" alt="" className="h-img img-fluid" />
          <Carousel.Caption>
            <h1 className="h-heading">STAY WITH US, FEEL AT HOME</h1>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel> */}

      <div className="home-part-2 p-3" data-aos="zoom-in" data-aos-duration="2000">

        <Container>
          <div className="row">
            <div className="col-md-5 left-img">
              <img
                src="/Assets/estate2.jpg"
                alt=""
                style={{ borderRadius: "8px" }}
                className="side-img img-fluid"
              />
            </div>
            <div className=" col-md-7 home-welcome p-5 " data-aos="fade-down" data-aos-duration="2000">
              <div className=" home-welcome-text ">
                {/* <h1 className="mt-4 text-center" style={{ color: "#AF6327" }} >WELCOME</h1>
                <h1 className="mt-4 text-center" > OUR HOME STAY!</h1> */}
                <h1><b style={{ color: "#AF6327" }}>WELCOME</b> TO OUR HOME STAY!</h1>
              </div>
              <h6 className="text-center">SOME WORDS ABOUT US</h6>
              <div className="middle-text mt-5">
                <h5 className="guru-krupa-text text-center">
                  Guru Krupa Homestay is located in the heart of coffee country
                  in Coorg, Karnataka.
                </h5>
                <p className="mt-4 guru-krupa-text text-center">
                  Escape from the hustle and hassle of your everyday routine and
                  come away to the Guru Krupa Homestay, where a serene world
                  awaits you. Throw off all your worries and slip into the
                  scenic, relaxed atmosphere and be pampered and rejuvenated.
                </p>
              </div>
              <div className="explore-btn mt-3 text-center">
                <button
                  className="explore-btn-color "
                  onClick={handleAboutusClick}
                >
                  EXPLORE MORE
                </button>
              </div>
            </div>

            {/* <div className="col-md-5 mt-3 ">
              <img
                src="/Assets/h-img4.jpg"
                alt=""
                className="side-img img-fluid"
              />
            </div>
            <div className="col-md-5 right-bar " data-aos="fade-down" data-aos-duration="2000">
              <h1 className="mt-4">WELCOME HOME!</h1>
              <h6 className="text-center">SOME WORD ABOUT US</h6>
              <div className="middle-text mt-5">
                <h5 className="guru-krupa-text">
                  Guru Krupa Homestay is located in the heart of coffee country
                  in Coorg, Karnataka.
                </h5>
                <p className="mt-4">
                  Escape from the hustle and hassle of your everyday routine and
                  come away to the Guru Krupa Homestay, where a serene world
                  awaits you. Throw off all your worries and slip into the
                  scenic, relaxed atmosphere and be pampered and rejuvenated.
                </p>
              </div>
              <div className="explore-btn mt-3">
                <button
                onClick={handleAboutusClick}
                className="explore-btn-color"
                >
                  EXPLORE MORE
                </button>
              </div>
            </div> */}

          </div>
        </Container>

      </div>


      <div className="featured-facilities-section" style={{ backgroundColor: '#F8F9FA', padding: '20px 0' }} data-aos="zoom-out" data-aos-duration="2000">
        <div className="container featured-facilities-section1">
          <div className="fea-head text-center mb-5">
            <h1>FEATURED FACILITIES</h1>
          </div>
          <div className="row d-flex justify-content-evenly">
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
              <div className="card h-100" data-aos="fade-up" data-aos-duration="2000">
                <div className="card-img-top">
                  <p className="feature-icon mt-2"><MdOutlineSecurity /></p>
                </div>
                <div className="card-body">
                  <h5 className="card-title">24/7 Security(CCTV)</h5>
                  <p className="card-text para-jus">
                    We take safety seriously through our dedicated team that stands watch.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
              <div className="card h-100" data-aos="fade-down" data-aos-duration="2000">
                <div className="card-img-top">
                  <p className="feature-icon mt-2"><FaWifi /></p>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Free Wifi</h5>
                  <p className="card-text para-jus">
                    All rooms have good high-speed Wi-Fi connectivity.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
              <div className="card h-100" data-aos="fade-up" data-aos-duration="2000">
                <div className="card-img-top">
                  <p className="feature-icon mt-2"><TbToolsKitchen3 /></p>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Kitchen</h5>
                  <p className="card-text para-jus">
                    At our homestay, we understand that part of the joy of traveling is experiencing new flavors and enjoying home-cooked meals.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
              <div className="card h-100" data-aos="fade-down" data-aos-duration="2000">
                <div className="card-img-top">
                  <p className="feature-icon mt-2"><FaHandHoldingWater /></p>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Hot Water</h5>
                  <p className="card-text para-jus">
                    We provide 24/7 hot water facilities to our guests in all bathrooms.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="featured-facilities-section2">
          <img
            src="/Assets/estate8.jpg"
            alt=""

          />
        </div>
      </div>


      {/* <div className="facilityOuterDiv">
          <div>
          <div className="fea-head text-center mb-5 facilityOuterDiv1">
            <h1>FEATURED FACILITIES</h1>
          </div>
          <div className="row d-flex justify-content-evenly">
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
              <div className="card h-100">
                <div className="card-img-top">
                  <p className="feature-icon mt-2"><MdOutlineSecurity /></p>
                </div>
                <div className="card-body">
                  <h5 className="card-title">24/7 Security(CCTV)</h5>
                  <p className="card-text para-jus">
                    We take safety seriously through our dedicated team that stands watch.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
              <div className="card h-100">
                <div className="card-img-top">
                  <p className="feature-icon mt-2"><FaWifi /></p>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Free Wifi</h5>
                  <p className="card-text para-jus">
                    All rooms have good high-speed Wi-Fi connectivity.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
              <div className="card h-100">
                <div className="card-img-top">
                  <p className="feature-icon mt-2"><TbToolsKitchen3 /></p>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Kitchen</h5>
                  <p className="card-text para-jus">
                    At our homestay, we understand that part of the joy of traveling is experiencing new flavors and enjoying home-cooked meals.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
              <div className="card h-100">
                <div className="card-img-top">
                  <p className="feature-icon mt-2"><FaHandHoldingWater /></p>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Hot Water</h5>
                  <p className="card-text para-jus">
                    We provide 24/7 hot water facilities to our guests in all bathrooms.
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="facilityOuterDiv2">
          <img
                src="/Assets/h-img4.jpg"
                alt=""
                className="facilityimg"
              />
          </div>
        </div> */}


      <div className="things-to-do">
        <Container className="things-to-do-cont">

          <div className="things-heading ">
            <h1>Things To Do</h1>
          </div>
          <div className="row thinks-media mt-4">
            <div className="col-md-4" onClick={thingsToDo}>
              <img src="/Assets/night-campfire.jpg" alt="" className="things-img img-fluid" data-aos="fade-down" data-aos-duration="2000" />
              <h3 className="text-center mt-2" >Fire Camp</h3>
            </div>
            <div className="col-md-4" onClick={thingsToDo}>
              <img src="/Assets/estate5.jpg" alt="" className="things-img img-fluid" data-aos="fade-up" data-aos-duration="2000" />
              <h3 className="text-center mt-2">Estate Walk</h3>
            </div>
            <div className="col-md-4" onClick={thingsToDo}>
              <img src="/Assets/walk.jpg" alt="" className="things-img img-fluid" data-aos="fade-down" data-aos-duration="2000" />
              <h3 className="text-center mt-2">Stream Flow </h3>
            </div>
          </div>

        </Container>
      </div>

      <div className="row you-can-explore-main  mt-5">
        <div className="you-can-explore p-2">
          <h2>You Can Explore More Activities</h2>
        </div>
        <div className="col-md-6 in-out-heading mt-3">
          <div className="p-2 indoorHeading">
            <h3 style={{ color: "#AF6327" }}>Indoor </h3>
            <h3>Activities</h3>
          </div>
          <Container className="cont">
            <div className="Indoor ">
              <div className="indoorGames" style={{ width: "48%" }} data-aos="zoom-in" data-aos-duration="2000">
                <img src="/Assets/indoor1.jpg" alt="" id="indoor1" className="indoorImg" />
                <div className="indoorText">
                  <h5>Caroom</h5>
                </div>
              </div>
              <div className="indoorGames" style={{ width: "47%" }} data-aos="fade-down" data-aos-duration="2000">
                <img src="/Assets/indoor2.jpg" alt="" className="indoorImg" />
                <div className="indoorText">
                  <h5>Chess</h5>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="col-md-6 in-out-heading mt-3">
          <div className="p-2 indoorHeading ">
            <h3 style={{ color: "#AF6327" }}>Outdoor </h3>
            <h3>Activities</h3>
          </div>
          <Container className="cont" >
            <div className="Indoor">
              <div className="indoorGames" data-aos="zoom-in" data-aos-duration="2000">
                <img src="/Assets/outdoor1.jpg" alt="" className="indoorImg" />
                <div className="indoorText">
                  <h5>Cricket</h5>
                </div>
              </div>
              <div className="indoorGames" data-aos="fade-down" data-aos-duration="2000">
                <img src="/Assets/outdoor2.jpg" alt="" className="indoorImg" />
                <div className="indoorText">
                  <h5>Shettle</h5>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <div className="mt-5 nearby-main" >
        <div className="near-places">
          <h2>Near By Places</h2>
        </div>
        <Container>
          <div className="row " data-aos="fade-down" data-aos-duration="2000">

            <div className="col-md-4 mb-2">
              <div className="place-img-container">
                <img src="/Assets/madikeri.jpg" alt="" className="place-img img-fluid" />
                <div className="place-text">
                  <h4 className="text-center mt-2">Madikeri - 22 kms</h4>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-2">
              <div className="place-img-container">
                <img src="/Assets/dubare2.jpg" alt="" className="place-img img-fluid" />
                <div className="place-text">
                  <h4 className="text-center mt-2">Dubare Forest - 50 kms</h4>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-2">
              <div className="place-img-container">
                <img src="/Assets/Nisargadhama.jpg" alt="" className="place-img img-fluid" />
                <div className="place-text">
                  <h4 className="text-center mt-2">Nisargadhama - 48 kms</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="row nearby-sub mt-5" data-aos="fade-up" data-aos-duration="2000">
            <div className="col-md-4 mb-2">
              <div className="place-img-container">
                <img src="/Assets/GoldenTemple.jpg" alt="" className="place-img img-fluid" />
                <div className="place-text">
                  <h4 className="text-center mt-2">Golden Temple - 56 kms</h4>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-2">
              <div className="place-img-container">
                <img src="/Assets/Talacauvery.jpg" alt="" className="place-img img-fluid" />
                <div className="place-text">
                  <h4 className="text-center mt-2">Talacauvery - 15 kms</h4>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-2">
              <div className="place-img-container">
                <img src="/Assets/Nagarahole.jpg" alt="" className="place-img img-fluid" />
                <div className="place-text">
                  <h4 className="text-center mt-2">Nagarahole - 85 kms</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="row nearby-sub mt-5" data-aos="fade-down" data-aos-duration="2000">
            <div className="col-md-4 mb-2">
              <div className="place-img-container">
                <img src="/Assets/Kakkabe.jpg" alt="" className="place-img img-fluid" />
                <div className="place-text">
                  <h4 className="text-center mt-2">Kakkabe - 16 kms</h4>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-2">
              <div className="place-img-container">
                <img src="/Assets/NalknadAramane.jpg" alt="" className="place-img img-fluid" />
                <div className="place-text">
                  <h4 className="text-center mt-2">Nalknad Aramane - 16 kms</h4>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-2">
              <div className="place-img-container">
                <img src="/Assets/HarangiDam.jpg" alt="" className="place-img img-fluid" />
                <div className="place-text">
                  <h4 className="text-center mt-2">Harangi Dam - 56 kms</h4>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};


export default Home;